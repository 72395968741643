document.addEventListener("DOMContentLoaded", () => {
  const smartWidgetWrapper = document.querySelector("#smart-widget-wrapper"),
        smartAssist = document.createElement('div'),
        overlay = document.createElement('div'),
        body = document.querySelector('body'),
        widgetContainer = document.createElement('div'),
        iframe = document.createElement('iframe'),
        protocol = smartWidgetWrapper.getAttribute('data-protocol'),
        domain = smartWidgetWrapper.getAttribute('data-domain');

  iframe.style.width = '100%';
  iframe.style.height = '100%';
  iframe.style.border = '0 none';
  iframe.setAttribute('src', `${protocol}://${domain}/widget/?ajax=1&is_frame=true`);

  smartAssist.classList.add('smart-assist');
  overlay.setAttribute('id', 'modal-problem-create-fon');
  widgetContainer.setAttribute('id', 'modal-problem-create-form-container');

  overlay.style.width = "100vw";
  overlay.style.height = "100vh";
  overlay.style.background = "rgba(0, 0, 0, 0.3)";
  overlay.style.position = "fixed";
  overlay.style.top = "0";
  overlay.style.left = "0";
  overlay.style.right = "0";
  overlay.style.bottom = "0";
  overlay.style.zIndex = "9999";

  const isMedia = window.matchMedia("(max-width: 767px)");
  widgetContainer.style.top = "0";
  widgetContainer.style.bottom = "0";
  widgetContainer.style.left = "0";
  widgetContainer.style.right = "0";
  widgetContainer.style.margin = "auto";
  widgetContainer.style.zIndex = "9999";
  widgetContainer.style.position = "fixed";
  if (isMedia.matches) {
    widgetContainer.style.overflowY = "auto";
    widgetContainer.style.overflowX = "hidden";
    widgetContainer.style.height = "inherit";
    widgetContainer.style.width = "inherit";
    widgetContainer.style.paddingTop = "10px";
    widgetContainer.style.paddingBottom = "10px";
  } else {
    widgetContainer.style.maxHeight = "800px";
    widgetContainer.style.maxWidth = "767px";
    widgetContainer.style.padding = "0";
  }

  const create = () => {
    widgetContainer.appendChild(iframe);
    smartAssist.appendChild(overlay);
    smartAssist.appendChild(widgetContainer);
    body.appendChild(smartAssist);
  };

  const destroy = () => {
    smartAssist.remove();
  };

  overlay.addEventListener('click', () => {
    destroy();
  });

  smartWidgetWrapper.addEventListener('touchstart',  e => {
    e.preventDefault();
    create();
  });

  smartWidgetWrapper.addEventListener('click', e => {
    e.preventDefault();
    create();
  });

  const handlerMessage = e => {
    const messageData = e.data.split(':');
    if(messageData[0] === 'closeAuth')
      destroy();
    else if(messageData[0] === 'sessionid')
      document.cookie = encodeURIComponent("sessionid") + '=' + encodeURIComponent(messageData[1]);
  };

  window.addEventListener ? window.addEventListener('message', handlerMessage) : window.attachEvent('message', handlerMessage);
});
